import {
  Button,
  Col,
  Divider,
  Form,
  Grid,
  Input,
  Row,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import {
  XOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  AlibabaOutlined,
  EnvironmentFilled,
  MailFilled,
  PhoneFilled,
  SendOutlined,
} from "@ant-design/icons";
import { colors } from "../..";
import { useContext, useState } from "react";
import { email, max, min, required } from "../../helpers/FormRuleHelper";
import { LanguageContext } from "../../context";
import { getLanguageValue } from "../../language";

const { Title } = Typography;
const { useBreakpoint } = Grid;

const ContactUs = () => {
  const { language } = useContext(LanguageContext);
  const screens = useBreakpoint();

  const [mapSrc, setMapSrc] = useState(
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12742.812968833052!2d35.25068820428435!3d37.01687540398349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1528888d8a7c1577%3A0x247cd7eac8d47800!2zR8O8cnNlbHBhxZ9hLCAwMTIwMCBTZXloYW4vQWRhbmE!5e0!3m2!1str!2str!4v1719612428212!5m2!1str!2str"
  );

  const handleSwitchChange = (checked: boolean) => {
    if (checked) {
      setMapSrc(
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12742.812968833052!2d35.25068820428435!3d37.01687540398349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1528888d8a7c1577%3A0x247cd7eac8d47800!2zR8O8cnNlbHBhxZ9hLCAwMTIwMCBTZXloYW4vQWRhbmE!5e0!3m2!1str!2str!4v1719612428212!5m2!1str!2str"
      );
    } else {
      setMapSrc(
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10054.325497693153!2d6.928641511900528!3d50.949954525630375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bf25a065a58709%3A0x1c2760ffab2591d0!2s50670%20Innenstadt%2C%20Almanya!5e0!3m2!1str!2str!4v1719612362687!5m2!1str!2str"
      );
    }
  };

  const handleSubmit = (values: any) => {
    const { username, email, message, phone } = values;
    const phoneNumber = "+905441311515";
    const whatsappMessage = `Merhaba ben ${username},%0A%0AE-posta adresim ${email}.%0A%0ANumaram ${phone}.%0A%0A"${message}" hakkında görüşmek istiyorum.%0A%0ATeşekkürler.`;
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${whatsappMessage}`;

    window.open(whatsappUrl, "_blank");
  };

  return (
    <div
      style={{
        width: screens.lg ? 1170 : "100%",
        padding: screens.lg ? 30 : "40px 24px",
      }}
    >
      <Title style={{ position: "relative" }}>
        {getLanguageValue("contact", language)}
        <img
          style={{
            position: "absolute",
            left: 0,
            top: 44,
            width: screens.xs ? "40%" : "12%",
          }}
          src="/assets/bottomLine.png"
          alt="img"
        />
      </Title>
      <Row gutter={[24, 24]} style={{ padding: "30px 0" }}>
        <Col lg={12} xs={24}>
          <Row>
            <Col span={24} style={{ paddingBottom: 20 }}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Title
                    level={3}
                    style={{
                      position: "relative",
                      paddingBottom: 20,
                      margin: 0,
                    }}
                  >
                    {getLanguageValue("address", language)}
                    <img
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 30,
                        width: "20%",
                      }}
                      src="/assets/bottomLine.png"
                      alt="img"
                    />
                  </Title>
                </Col>
                <Col>
                  {getLanguageValue("changeAddress", language)}-{" "}
                  <Switch
                    checkedChildren="TR"
                    unCheckedChildren="EU"
                    defaultChecked
                    onChange={handleSwitchChange}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <iframe
                src={mapSrc}
                style={{
                  border: 0,
                  width: "100%",
                  height: 400,
                  borderRadius: 10,
                }}
                loading="lazy"
                title="harita"
              />
            </Col>
          </Row>
        </Col>
        <Col lg={12} xs={24}>
          <Title level={3} style={{ position: "relative", paddingBottom: 20 }}>
            {getLanguageValue("contactUs", language)}
            <img
              style={{
                position: "absolute",
                left: 0,
                top: 30,
                width: screens.xs ? "50%" : "20%",
              }}
              src="/assets/bottomLine.png"
              alt="img"
            />
          </Title>
          <Row style={{ height: "100%" }} justify="space-between">
            <Col span={24}>
              <Form name="basic" layout="vertical" onFinish={handleSubmit}>
                <Row gutter={[8, 0]}>
                  <Col span={24}>
                    <Form.Item
                      label={getLanguageValue(
                        "contactUsFormFullName",
                        language
                      )}
                      name="username"
                      rules={[required(language)]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={getLanguageValue("contactUsFormEmail", language)}
                      name="email"
                      rules={[required(language), email(language)]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={getLanguageValue("contactUsFormPhone", language)}
                      name="phone"
                      rules={[required(language)]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={getLanguageValue("contactUsFormMessage", language)}
                      name="message"
                      rules={[
                        required(language),
                        min(20, language),
                        max(500, language),
                      ]}
                    >
                      <Input.TextArea rows={3} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      iconPosition="end"
                      icon={<SendOutlined />}
                    >
                      {getLanguageValue("contactUsFormSend", language)}
                    </Button>
                  </Col>
                </Row>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}></Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
        <Divider style={{ marginTop: 0 }} />
        <Col span={24}>
          <Row gutter={[12, 40]}>
            <Col lg={12} xs={24}>
              <Row gutter={[12, 12]}>
                <Title level={5} style={{ margin: 0 }}>
                  {getLanguageValue("turkey", language)}
                </Title>
                <Col span={24}>
                  <EnvironmentFilled style={{ paddingRight: 10 }} /> GÜRSELPAŞA
                  MAH. 75409 SK. ARMADA LOFT SİTESİ A BLOK NO: 1 SEYHAN/ ADANA
                </Col>
                <Col span={24}>
                  <PhoneFilled style={{ paddingRight: 10 }} />
                  +90 544 131 15 15
                </Col>
                <Col span={24}>
                  <MailFilled style={{ paddingRight: 10 }} />
                  info@tforce.com.tr
                </Col>
                <Title level={5} style={{ margin: 0 }}>
                  {getLanguageValue("europe", language)}
                </Title>
                <Col span={24}>
                  <EnvironmentFilled style={{ paddingRight: 10 }} /> NİTROX
                  GREEN TECHNOLOGY & TRADE GMBH Christoph Street 40
                  Altstadt-Nord 50670 Köln-Deutschland
                </Col>
                <Col span={24}>
                  <PhoneFilled style={{ paddingRight: 10 }} />
                  +49 (0)1635720314
                </Col>
                <Col span={24}>
                  <MailFilled style={{ paddingRight: 10 }} />
                  info@nitroxgreen.com
                </Col>
              </Row>
            </Col>
            <Col lg={12} xs={24}>
              <Row justify={screens.lg ? "end" : "center"} gutter={[24, 24]}>
                <Col>
                  <Tooltip title="X">
                    <Button
                      shape="circle"
                      icon={<XOutlined style={{ color: colors.darkBlue }} />}
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip title="Instagram">
                    <Button
                      shape="circle"
                      icon={
                        <InstagramOutlined style={{ color: colors.darkBlue }} />
                      }
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip title="Linkedin">
                    <Button
                      shape="circle"
                      icon={
                        <LinkedinOutlined style={{ color: colors.darkBlue }} />
                      }
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip title="Alibaba">
                    <Button
                      shape="circle"
                      icon={
                        <AlibabaOutlined style={{ color: colors.darkBlue }} />
                      }
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Divider />
      </Row>
    </div>
  );
};

export default ContactUs;
