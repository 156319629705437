import { useContext, useState } from "react";
import AboutUsFrontItem from "./AboutUsFrontItem";
import { Badge, Col, Divider, Grid, Row, Typography } from "antd";
import AboutUsBackItem from "./AboutUsBackItem";
import { colors } from "../..";
import { getLanguageValue } from "../../language";
import { LanguageContext } from "../../context";

const { Title } = Typography;

interface ICardItemProps {
  id: string;
  front: JSX.Element;
  back: JSX.Element;
}

const { useBreakpoint } = Grid;

const AboutUs = () => {
  const screens = useBreakpoint();
  const { language } = useContext(LanguageContext);

  const [hoveredCard, setHoveredCard] = useState<string | null>(null);

  const handleCardHover = (cardId: string) => setHoveredCard(cardId);
  const handleCardLeave = () => setHoveredCard(null);

  const cards: ICardItemProps[] = [
    {
      id: "1",
      front: (
        <AboutUsFrontItem
          image="https://img.freepik.com/free-photo/cows-grazing-nature_23-2150454969.jpg?t=st=1719530674~exp=1719534274~hmac=04b6aefe9b5f1e98892cc425a3140a189b413ac14a7499235e85b0d2ab460265&w=1380"
          title={getLanguageValue("petFoodCategoryName", language)}
          text={getLanguageValue("petFoodDescription", language)}
        />
      ),
      back: (
        <AboutUsBackItem
          title={getLanguageValue("petFoodCategoryName", language)}
          text={getLanguageValue("petFoodFlipDescription", language)}
        />
      ),
    },
  ];

  return (
    <div
      style={{
        width: screens.lg ? 1170 : "100%",
        padding: screens.lg ? "40px 0" : "40px 24px",
      }}
    >
      <Title style={{ position: "relative", margin: 0 }}>
        {getLanguageValue("aboutUs", language)}
        <img
          style={{
            position: "absolute",
            left: 0,
            top: 44,
            width: screens.xs ? "60%" : "20%",
          }}
          src="/assets/bottomLine.png"
          alt="img"
        />
      </Title>
      <Row
        gutter={[0, 40]}
        justify="center"
        style={{ paddingTop: 40, paddingBottom: 40 }}
      >
        {screens.lg
          ? cards.map((card) => (
              <Col span={12} key={card.id}>
                <div
                  className={`flip-container ${
                    hoveredCard === card.id ? "hover" : ""
                  }`}
                  onMouseEnter={() => handleCardHover(card.id)}
                  onMouseLeave={handleCardLeave}
                  style={{ height: 525 }}
                >
                  <div className="flipper">
                    {hoveredCard === card.id ? (
                      <div className="aboutUs-item-back">{card.back}</div>
                    ) : (
                      <div className="aboutUs-item-front">{card.front}</div>
                    )}
                  </div>
                </div>
              </Col>
            ))
          : cards.map((card) => (
              <Col span={24} style={{ paddingBottom: 20 }} key={card.id}>
                <div>
                  <div>{card.back}</div>
                </div>
              </Col>
            ))}

        <Col lg={12} xs={24}>
          <Badge.Ribbon
            text={getLanguageValue("comingSoon", language)}
            color={colors.green}
            style={{ marginRight: screens.lg ? 40 : 0 }}
          >
            <div
              className="flip-container"
              style={{ height: 525, opacity: 0.5 }}
            >
              <div>
                <div className="aboutUs-item-front">
                  <Row
                    style={{
                      boxShadow: " 0px 10px 5px 1px rgba(0, 0, 0, 0.1)",
                      borderRadius: "10px",
                      width: screens.lg ? "94%" : "100%",
                      backgroundColor: "#F0F0F0",
                      height: "auto",
                    }}
                  >
                    <Col span={24}>
                      <div
                        style={{
                          width: "100%",
                          height: 300,
                          backgroundImage: `url(https://www.afctarim.com/images/slider/3.jpg)`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          borderTopLeftRadius: 10,
                          borderTopRightRadius: 10,
                        }}
                      />
                    </Col>
                    <div style={{ width: "100%", padding: 20 }}>
                      <Col span={24}>
                        <Title level={3}>
                          {getLanguageValue(
                            "agriculturalCategoryName",
                            language
                          )}
                        </Title>
                        {getLanguageValue("agriculturalDescription", language)}
                      </Col>
                      <Divider
                        style={{
                          borderBlockStart: "1px solid #C0C0C0",
                        }}
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </Badge.Ribbon>
        </Col>
      </Row>
    </div>
  );
};

export default AboutUs;
