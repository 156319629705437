import { Col, Divider, Grid, Row, Typography } from "antd";
import { colors } from "../..";

const { Title } = Typography;
const { useBreakpoint } = Grid;

interface IAboutUsBackItemProps {
  title?: string;
  subTitle?: string;
  text?: string;
  completionRate?: number;
  remainingDay?: number;
  targetPrice?: any;
}

const AboutUsBackItem = (props: IAboutUsBackItemProps) => {
  const screens = useBreakpoint();

  return (
    <Row
      style={{
        boxShadow: " 0px 10px 5px 1px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px ",
        width: screens.lg ? "94%" : "100%",
        backgroundColor: colors.lightBlue,
        color: "white",
        height: "auto",
        backgroundImage:
          "linear-gradient(rgb(28, 96, 75,0.7),rgb(28, 96, 75,0.7))",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div style={{ width: "100%", padding: 20 }}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Title level={1} style={{ color: "white", fontWeight: "bold" }}>
              {props.title}
            </Title>
            <Title level={4} style={{ color: "white" }}>
              {props.subTitle}
            </Title>
            <div style={{ height: screens.xs ? 400 : 250 }}>{props.text}</div>
          </Col>
          <Divider
            style={{
              borderBlockStart: "1px solid white",
            }}
          />
        </Row>
      </div>
    </Row>
  );
};

export default AboutUsBackItem;
