import { Carousel, Row, Image, Col } from "antd";
import AboutUs from "../components/home/AboutUs";
import ContactUs from "../components/home/ContactUs";

const HomePage = () => {
  const urls = [
    "/assets/sliders/1.jpg",
    "/assets/sliders/2.jpg",
    "/assets/sliders/3.jpg",
  ];

  return (
    <>
      <Carousel autoplay autoplaySpeed={3000} pauseOnDotsHover={true} draggable>
        {urls.map((url, index) => (
          <Row key={index} justify="center" align="middle">
            <Col span={24}>
              <Image src={url} width="100%" preview={false} />
            </Col>
          </Row>
        ))}
      </Carousel>
      <Row justify="center">
        <div id="hakkimizda">
          <AboutUs />
        </div>

        <Row
          justify="center"
          align="middle"
          style={{
            backgroundColor: "#eee",
            width: "100%",
            marginTop: -60,
          }}
        >
          <div id="iletisim">
            <ContactUs />
          </div>
        </Row>
      </Row>
    </>
  );
};

export default HomePage;
