import {
  Layout,
  Row,
  Image,
  Col,
  Typography,
  Button,
  MenuProps,
  Dropdown,
  Tooltip,
  Grid,
  Drawer,
} from "antd";
import {
  XOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  AlibabaOutlined,
  GlobalOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { colors, heights } from "../..";
import { useContext, useState } from "react";
import { LanguageContext } from "../../context";
import { getLanguageValue } from "../../language";

const { useBreakpoint } = Grid;

const { Header } = Layout;
const { Text, Title } = Typography;

const CustomHeader = () => {
  const screens = useBreakpoint();
  const { language, setLanguage } = useContext(LanguageContext);

  const [open, setOpen] = useState(false);

  const showDrawer = () => setOpen(true);
  const onClose = () => setOpen(false);
  const handleScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) element.scrollIntoView({ behavior: "smooth" });
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <Text style={{ paddingLeft: 10 }}>
          {getLanguageValue("turkish", language)}
        </Text>
      ),
      key: "0",
      icon: (
        <Image
          preview={false}
          src="/assets/flags/tr.png"
          height={20}
          width={30}
        />
      ),
      onClick: () => setLanguage("tr"),
    },
    {
      type: "divider",
    },
    {
      label: (
        <Text style={{ paddingLeft: 10 }}>
          {getLanguageValue("german", language)}
        </Text>
      ),
      key: "2",
      icon: (
        <Image
          preview={false}
          src="/assets/flags/de.png"
          height={20}
          width={30}
        />
      ),
      onClick: () => setLanguage("de"),
    },
    {
      type: "divider",
    },
    {
      label: (
        <Text style={{ paddingLeft: 10 }}>
          {getLanguageValue("english", language)}
        </Text>
      ),
      key: "4",
      icon: (
        <Image
          preview={false}
          src="/assets/flags/en.png"
          height={20}
          width={30}
        />
      ),
      onClick: () => setLanguage("en"),
    },
    {
      type: "divider",
    },
    {
      label: (
        <Text style={{ paddingLeft: 10 }}>
          {getLanguageValue("arabic", language)}
        </Text>
      ),
      key: "6",
      icon: (
        <Image
          preview={false}
          src="/assets/flags/ar.png"
          height={20}
          width={30}
        />
      ),
      onClick: () => setLanguage("ar"),
    },
  ];

  const languageDropdown = (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      placement={screens.lg ? "bottom" : "bottomLeft"}
      arrow
    >
      <GlobalOutlined
        onClick={(e: any) => e.preventDefault()}
        style={{ color: colors.darkBlue }}
      />
    </Dropdown>
  );

  return (
    <>
      <Row
        id="anasayfa"
        justify="center"
        align="middle"
        style={{
          backgroundColor: "#98bc1c",
          height: screens.lg ? heights.upperHeaderHeight : 80,
        }}
      >
        <Col style={{ width: screens.lg ? 1170 : "100%" }}>
          <Row
            justify="space-between"
            align="middle"
            style={{ padding: screens.lg ? "" : "0 16px" }}
          >
            <Col lg={16} md={24} xs={24}>
              <Row
                align="middle"
                justify={screens.lg ? "start" : "center"}
                gutter={[8, 0]}
              >
                <Col sm={6} xs={12}>
                  <Image
                    src="/assets/flags/turkey.png"
                    preview={false}
                    height={16}
                    width={16}
                    style={{ marginTop: -2 }}
                  />{" "}
                  <Text
                    style={{ color: "white", fontSize: screens.lg ? 13 : 12 }}
                  >
                    +90 544 131 15 15
                  </Text>
                </Col>
                <Col sm={6} xs={12}>
                  <Text
                    style={{ color: "white", fontSize: screens.lg ? 13 : 12 }}
                  >
                    info@tforce.com.tr
                  </Text>
                </Col>
                <Col sm={1} xs={0}>
                  <Text strong style={{ color: "white" }}>
                    |
                  </Text>
                </Col>
                <Col sm={5} xs={12}>
                  <Image
                    src="/assets/flags/euFlag.png"
                    preview={false}
                    height={16}
                    width={16}
                    style={{ marginTop: -2 }}
                  />{" "}
                  <Text
                    style={{ color: "white", fontSize: screens.lg ? 13 : 12 }}
                  >
                    +49 (0)1635720314
                  </Text>
                </Col>
                <Col sm={6} xs={12}>
                  <Text
                    style={{ color: "white", fontSize: screens.lg ? 13 : 12 }}
                  >
                    info@nitroxgreen.com
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col lg={8} md={24} xs={24}>
              <Row justify={screens.lg ? "end" : "center"} gutter={[24, 24]}>
                <Col>
                  <Tooltip title="X">
                    <Button
                      shape="circle"
                      style={{ backgroundColor: "transparent", border: "none" }}
                      icon={<XOutlined style={{ color: "white" }} />}
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip title="Instagram">
                    <Button
                      shape="circle"
                      style={{ backgroundColor: "transparent", border: "none" }}
                      icon={<InstagramOutlined style={{ color: "white" }} />}
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip title="Linkedin">
                    <Button
                      shape="circle"
                      style={{ backgroundColor: "transparent", border: "none" }}
                      icon={<LinkedinOutlined style={{ color: "white" }} />}
                    />
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip title="Alibaba">
                    <Button
                      shape="circle"
                      style={{ backgroundColor: "transparent", border: "none" }}
                      icon={<AlibabaOutlined style={{ color: "white" }} />}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Header
        style={{
          backgroundColor: "white",
          height: heights.headerHeight,
          borderBottom: "2px solid #eee",
          padding: screens.lg ? "" : "0 24px",
        }}
      >
        <Row justify="center" align="middle" style={{ height: "100%" }}>
          <Col style={{ width: screens.lg ? 1170 : "100%" }}>
            <Row justify="space-between" align="middle">
              <Col>
                <Image
                  preview={false}
                  src="/assets/logo/logo.png"
                  height={screens.lg ? 70 : 40}
                  width={screens.lg ? 256 : 146}
                />
              </Col>
              <Col>
                {screens.md ? (
                  <Row gutter={[24, 0]}>
                    <Col>
                      <Title
                        level={5}
                        style={{
                          cursor: "pointer",
                          margin: 0,
                          color: colors.darkBlue,
                        }}
                        onClick={() => handleScroll("anasayfa")}
                      >
                        {getLanguageValue("homePage", language)}
                      </Title>
                    </Col>
                    <Col>
                      <Title
                        level={5}
                        style={{
                          cursor: "pointer",
                          margin: 0,
                          color: colors.darkBlue,
                        }}
                        onClick={() => handleScroll("hakkimizda")}
                      >
                        {getLanguageValue("aboutUs", language)}
                      </Title>
                    </Col>
                    <Col>
                      <Title
                        level={5}
                        style={{
                          cursor: "pointer",
                          margin: 0,
                          color: colors.darkBlue,
                        }}
                        onClick={() => handleScroll("iletisim")}
                      >
                        {getLanguageValue("contact", language)}
                      </Title>
                    </Col>
                    <Col>
                      <Title level={5} style={{ margin: 0 }}>
                        {languageDropdown}
                      </Title>
                    </Col>
                  </Row>
                ) : (
                  <Row gutter={[24, 0]}>
                    <Col>
                      <Button icon={<MenuOutlined />} onClick={showDrawer} />
                      <Drawer
                        title="T-Force"
                        onClose={onClose}
                        open={open}
                        placement="left"
                        width={250}
                      >
                        <Row gutter={[0, 12]}>
                          <Col span={24}>
                            <Title
                              level={5}
                              style={{
                                cursor: "pointer",
                                margin: 0,
                                color: colors.darkBlue,
                              }}
                              onClick={() => {
                                handleScroll("anasayfa");
                                onClose();
                              }}
                            >
                              {getLanguageValue("homePage", language)}
                            </Title>
                          </Col>
                          <Col span={24}>
                            <Title
                              level={5}
                              style={{
                                cursor: "pointer",
                                margin: 0,
                                color: colors.darkBlue,
                              }}
                              onClick={() => {
                                handleScroll("hakkimizda");
                                onClose();
                              }}
                            >
                              {getLanguageValue("aboutUs", language)}
                            </Title>
                          </Col>
                          <Col span={24}>
                            <Title
                              level={5}
                              style={{
                                cursor: "pointer",
                                margin: 0,
                                color: colors.darkBlue,
                              }}
                              onClick={() => {
                                handleScroll("iletisim");
                                onClose();
                              }}
                            >
                              {getLanguageValue("contact", language)}
                            </Title>
                          </Col>
                        </Row>
                      </Drawer>
                    </Col>
                    <Col>{languageDropdown}</Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>
    </>
  );
};

export default CustomHeader;
