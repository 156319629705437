import Icon from "@ant-design/icons";

const EuIcon = (props: any) => (
  <Icon
    {...props}
    component={() => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width="24"
        height="24"
      >
        <g fillRule="nonzero">
          <path
            fill="#4D4D4D"
            d="M256 0c70.684 0 134.689 28.663 181.013 74.987C483.337 121.31 512 185.316 512 256c0 70.684-28.663 134.689-74.987 181.013C390.689 483.336 326.684 512 256 512c-70.676 0-134.689-28.664-181.013-74.987C28.664 390.689 0 326.676 0 256c0-70.684 28.664-134.69 74.987-181.013C121.311 28.663 185.316 0 256 0z"
          />
          <path
            fill="#fff"
            d="M256.002 19.596c65.279 0 124.383 26.462 167.161 69.24 42.772 42.777 69.239 101.885 69.239 167.164 0 65.28-26.462 124.384-69.239 167.161-42.778 42.777-101.882 69.239-167.161 69.239-65.28 0-124.388-26.466-167.165-69.239C46.06 380.384 19.598 321.28 19.598 256c0-65.279 26.466-124.387 69.239-167.164 42.777-42.774 101.885-69.24 167.165-69.24z"
          />
          <path
            fill="#039"
            d="M256.002 39.591c119.517 0 216.404 96.892 216.404 216.409s-96.887 216.405-216.404 216.405C136.48 472.405 39.593 375.517 39.593 256S136.48 39.591 256.002 39.591z"
          />
          <path
            fill="#FFCC02"
            d="M240.694 435.8L256 424.679l15.305 11.121-5.845-17.995 15.305-11.12h-18.918L256 388.69l-5.848 17.995h-18.918l15.305 11.12-5.845 17.995zM256 76.196l-5.848 17.993h-18.918l15.305 11.122-5.845 17.992L256 112.183l15.305 11.12-5.845-17.994 15.305-11.12h-18.918L256 76.196zm102.889 309.555l-15.305 11.12 5.845 17.995-15.305-11.12-15.308 11.12 5.847-17.995-15.308-11.12h18.921l5.848-17.994 5.845 17.994h18.92zm57.191-57.19l-15.306 11.12 5.846 17.995-15.306-11.121-15.308 11.121 5.848-17.995-15.308-11.12h18.92l5.848-17.995 5.848 17.995h18.918zm-24.766-174.243l5.848 17.995h18.918l-15.306 11.12 5.846 17.994-15.306-11.12-15.308 11.12 5.848-17.994-15.308-11.12h18.92l5.848-17.995zm-81.959-39.196h18.921l5.848-17.995 5.847 17.995h18.918l-15.308 11.123 5.848 17.992-15.305-11.12-15.308 11.12 5.847-17.992-15.308-11.123zm118.198 164.429l-15.305-11.12-15.308 11.12 5.847-17.994-15.308-11.12H406.4l5.848-17.995 5.847 17.995h18.918l-15.305 11.122 5.845 17.992zm-274.443 106.2l15.305 11.12-5.845 17.995 15.306-11.12 15.307 11.12-5.847-17.995 15.308-11.12h-18.921l-5.847-17.994-5.846 17.994h-18.92zm-57.191-57.19l15.306 11.12-5.845 17.995 15.305-11.121 15.308 11.121-5.848-17.995 15.308-11.12h-18.92l-5.848-17.995-5.848 17.995H95.919zm24.766-174.243l-5.848 17.995H95.919l15.306 11.12-5.845 17.994 15.305-11.12 15.308 11.12-5.848-17.994 15.308-11.12h-18.92l-5.848-17.995zm81.959-39.196h-18.921l-5.847-17.995-5.848 17.995H153.11l15.308 11.123-5.848 17.992 15.306-11.12 15.307 11.12-5.847-17.992 15.308-11.123zM84.446 279.551l15.305-11.12 15.308 11.12-5.847-17.994 15.308-11.12h-18.921l-5.848-17.995-5.847 17.995H74.986l15.305 11.122-5.845 17.992z"
          />
        </g>
      </svg>
    )}
  />
);

export default EuIcon;
