import { Col, Divider, Grid, Row, Typography } from "antd";

const { Title } = Typography;
const { useBreakpoint } = Grid;

interface IAboutUsFrontItemProps {
  image?: any;
  title?: string;
  text?: string;
  completionRate?: number;
  remainingDay?: number;
  targetPrice?: any;
}

const AboutUsFrontItem = (props: IAboutUsFrontItemProps) => {
  const screens = useBreakpoint();
  return (
    <Row
      style={{
        boxShadow: " 0px 10px 5px 1px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px ",
        width: screens.lg ? "94%" : "100%",
        backgroundColor: "#F0F0F0",
        height: "auto",
      }}
    >
      <Col span={24}>
        <div
          style={{
            width: "100%",
            height: 300,
            backgroundImage: `url(${props.image})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        />
      </Col>
      <div style={{ width: "100%", padding: 20 }}>
        <Col span={24}>
          <Title level={3}>{props.title}</Title>
          {props.text}
        </Col>
        <Divider
          style={{
            borderBlockStart: "1px solid #C0C0C0",
          }}
        />
      </div>
    </Row>
  );
};

export default AboutUsFrontItem;
