import { Layout, Button, FloatButton, Divider } from "antd";
import { UpOutlined, WhatsAppOutlined } from "@ant-design/icons";
import CustomHeader from "./CustomHeader";
import { Link } from "react-router-dom";
import { colors } from "../..";
import TrIcon from "../icons/TrIcon";
import EuIcon from "../icons/EuIcon";
import { getLanguageValue } from "../../language";
import { useContext } from "react";
import { LanguageContext } from "../../context";

interface IBasicLayoutProps {
  children?: any;
}
const { Content, Footer } = Layout;

const BasicLayout = (props: IBasicLayoutProps) => {
  const { language } = useContext(LanguageContext);

  const handleTrWhatsAppClick = () => {
    const phoneNumber = "+905441311515";
    const message = "Merhaba, size ulaşmak istiyorum.";
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleDeWhatsAppClick = () => {
    const phoneNumber = "+4901635720314";
    const message = "Hallo, ich möchte Sie erreichen.";
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Layout
      style={{
        backgroundColor: "white",
        maxWidth: "100%",
        overflowX: "hidden",
      }}
    >
      <CustomHeader />
      <Content>
        {props.children}
        <FloatButton.Group
          type="primary"
          trigger="click"
          tooltip={<div>{getLanguageValue("contactUsWithWP", language)}</div>}
          icon={<WhatsAppOutlined />}
        >
          <FloatButton
            tooltip={<div>{getLanguageValue("turkey", language)}</div>}
            onClick={handleTrWhatsAppClick}
            badge={{ count: "TR", color: "red" }}
            icon={<TrIcon style={{ marginLeft: -3, height: 24, width: 24 }} />}
          />
          <FloatButton
            tooltip={<div>{getLanguageValue("europe", language)}</div>}
            onClick={handleDeWhatsAppClick}
            badge={{ count: "EU", color: "blue" }}
            icon={<EuIcon style={{ marginLeft: -3, height: 24, width: 24 }} />}
          />
        </FloatButton.Group>
      </Content>
      <Divider style={{ padding: 0, margin: 0, backgroundColor: "#eee" }}>
        <Button
          type="primary"
          shape="circle"
          style={{ border: "bone" }}
          icon={<UpOutlined />}
          onClick={() => handleScroll("anasayfa")}
        />
      </Divider>
      <Footer style={{ textAlign: "center" }}>
        <Link to="https://tforce.com.tr/" style={{ color: colors.darkBlue }}>
          tforce.com.tr
        </Link>{" "}
        {getLanguageValue("footerBand", language)}
      </Footer>
    </Layout>
  );
};

export default BasicLayout;
