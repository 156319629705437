import ReactDOM from "react-dom/client";
import App from "./App";
import "./style/general.css";

export const colors = {
  darkBlue: "#02455e",
  lightBlue: "#6c91a1 ",
  green: "#1c604b",
  textColor: "#162457",
};

export const heights = {
  upperHeaderHeight: 40,
  headerHeight: 90,
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
