import Icon from "@ant-design/icons";

const TrIcon = (props: any) => (
  <Icon
    {...props}
    component={() => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width="24"
        height="24"
      >
        <g fillRule="nonzero">
          <path
            fill="#4D4D4D"
            d="M256 0c70.683 0 134.689 28.664 181.012 74.987C483.336 121.311 512 185.316 512 256c0 70.684-28.664 134.689-74.988 181.013C390.689 483.337 326.683 512 256 512c-70.676 0-134.689-28.663-181.013-74.987C28.664 390.689 0 326.676 0 256c0-70.684 28.664-134.689 74.987-181.013C121.311 28.664 185.316 0 256 0z"
          />
          <path
            fill="#fff"
            d="M256.001 19.596c65.278 0 124.383 26.467 167.162 69.243 42.777 42.78 69.242 101.884 69.242 167.162s-26.465 124.383-69.245 167.16c-42.776 42.779-101.881 69.245-167.159 69.245-65.278 0-124.383-26.466-167.162-69.242-42.777-42.78-69.243-101.885-69.243-167.163S46.062 131.619 88.839 88.839c42.779-42.776 101.884-69.243 167.162-69.243z"
          />
          <path
            fill="#E30A17"
            d="M256.001 39.594c119.517 0 216.407 96.887 216.407 216.407 0 119.518-96.89 216.407-216.407 216.407-119.52 0-216.407-96.889-216.407-216.407 0-119.52 96.887-216.407 216.407-216.407z"
          />
          <path
            fill="#fff"
            d="M280.099 201.21c-18.178-28.707-50.215-47.771-86.708-47.771-56.642 0-102.56 45.918-102.56 102.562 0 56.643 45.918 102.561 102.56 102.561 36.492 0 68.53-19.062 86.708-47.769-15.02 16.73-36.813 27.257-61.067 27.257-45.315 0-82.049-36.735-82.049-82.049 0-45.314 36.734-82.05 82.049-82.05 24.254 0 46.047 10.529 61.067 27.259zm-5.513 54.791l92.768 30.142-57.334-78.913v97.541l57.334-78.913-92.768 30.143z"
          />
        </g>
      </svg>
    )}
  />
);

export default TrIcon;
