import { Rule } from "antd/es/form";
import { getLanguageValue } from "../language";

export const required = (language: string) => ({
  required: true,
  message: getLanguageValue("requiredFormRuleMessage", language),
});

export const email = (language: string) =>
  ({
    type: "email",
    message: getLanguageValue("emailFormRuleMessage", language),
  } as Rule);

export const min = (length: number, language: string) => {
  return {
    min: length,
    message:
      getLanguageValue("minFormRuleMessage", language) +
      " (" +
      length +
      " " +
      getLanguageValue("character", language) +
      ")",
  };
};

export const max = (length: number, language: string) => {
  return {
    max: length,
    message:
      getLanguageValue("maxFormRuleMessage", language) +
      " (" +
      length +
      " " +
      getLanguageValue("character", language) +
      ")",
  };
};
