import { RouterProvider, createBrowserRouter } from "react-router-dom";
import BasicLayout from "./components/layout/BasicLayout";
import HomePage from "./pages/HomePage";
import { ConfigProvider } from "antd";
import { colors } from ".";
import { LanguageContext } from "./context";
import { useState } from "react";

const App = () => {
  const [language, setLanguage] = useState("tr");

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <BasicLayout>
          <HomePage />
        </BasicLayout>
      ),
    },
  ]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colors.darkBlue,
          colorText: colors.textColor,
        },
      }}
    >
      <LanguageContext.Provider
        value={{ language: language, setLanguage: setLanguage }}
      >
        <RouterProvider router={router} />
      </LanguageContext.Provider>
    </ConfigProvider>
  );
};

export default App;
