export const languageConstants = {
  tr: {
    homePage: "Anasayfa",
    aboutUs: "Hakkımızda",
    contact: "İletişim",
    comingSoon: "ÇOK YAKINDA !",
    petFoodCategoryName: "MAMA",
    petFoodDescription:
      "T-Force markası olarak kaliteden kesinlikle ödün vermeden ürettiğimiz yavru ve yetişkin kedi-köpek mamaları ile yurtiçi ve yurtdışı her türlü talebe cevap verebilmekteyiz.",
    petFoodFlipDescription:
      "T-Force markası olarak kaliteden kesinlikle ödün vermeden ürettiğimiz yavru ve yetişkin kedi-köpek mamaları ile yurtiçi ve yurtdışı her türlü talebe cevap verebilmekteyiz. İstenilen her rasyonda (besin değeri oranında)  üretim yapılabilmekte ve arzu edilen ambalaj çeşidi ve büyüklüğü ile de taleplere cevap verebilmekteyiz. 3000 m2 lik kapalı alanda hizmet verdiğimiz üretim tesisimizde ilk önceliğimiz hijyen, kalite ve zamanında teslimat. Özellikle ihracat için gerekli tüm kalite ve diğer belgelere sahip olduğumuz gibi Almanya-Köln’de de partner şirketimiz Avrupa operasyonunu yönetmektedir. T-Force markası veya talebiniz doğrultusunda kendi markanız adı altında size hizmet vermekten memnuniyet duyarız.",
    agriculturalCategoryName: "ZİRAİ ÜRÜN",
    agriculturalDescription:
      "Çok yakında zirai ürünlerimizle siz değerli müşterilerimizle olacağız.",
    address: "Adres",
    changeAddress: "Adres Değiştir",
    contactUs: "Bize Yazın",
    contactUsWithWP: "Whatsapp İle Bize Ulaş",
    contactUsFormFullName: "ADINIZ SOYADINIZ",
    contactUsFormEmail: "E-POSTA",
    contactUsFormPhone: "TELEFONUNUZ",
    contactUsFormMessage: "MESAJINIZ",
    contactUsFormSend: "Gönder",
    turkey: "Türkiye",
    europe: "Avrupa",
    footerBand: "© 2024 Telif Hakkı - Tüm Hakları Saklıdır.",
    turkish: "Türkçe",
    english: "İngilizce",
    german: "Almanca",
    arabic: "Arapça",
    requiredFormRuleMessage: "Boş bırakılamaz.",
    emailFormRuleMessage: "E-posta adresi geçersiz.",
    minFormRuleMessage: "Minimum karakter sınırını geçmelisiniz.",
    maxFormRuleMessage: "Maximum karakter sınırını geçmemelisiniz.",
    character: "Karakter",
  },
  en: {
    homePage: "Home",
    aboutUs: "About Us",
    contact: "Contact",
    comingSoon: "Coming Soon !",
    petFoodCategoryName: "PET FOOD",
    petFoodDescription:
      "(EN) Lorem ipsum dolor sit amet constectır adipsilising elit. Vero name ?",
    petFoodFlipDescription:
      "(EN) Sed convallis varius risus facilisis egestas. Curabitur lacus augue, blandit eu arcu ut, facilisis accumsan nulla.",
    agriculturalCategoryName: "AGRICULTURAL PRODUCT",
    agriculturalDescription:
      "We will be with you, our valued customers, with our agricultural products very soon.",
    address: "Address",
    changeAddress: "Change Address",
    contactUs: "Contact Us",
    contactUsWithWP: "Contact Us With Whatsapp",
    contactUsFormFullName: "FULLNAME",
    contactUsFormEmail: "EMAIL",
    contactUsFormPhone: "PHONE",
    contactUsFormMessage: "MESSAGE",
    contactUsFormSend: "Send",
    turkey: "Turkey",
    europe: "Europe",
    footerBand: "© 2024 Copyright - All Rights Reserved.",
    turkish: "Turkish",
    english: "English",
    german: "German",
    arabic: "Arabic",
    requiredFormRuleMessage: "Cannot be left blank.",
    emailFormRuleMessage: "Email address is invalid.",
    minFormRuleMessage: "You must exceed the minimum character limit.",
    maxFormRuleMessage: "You must not exceed the maximum character limit.",
    character: "Character",
  },
  de: {
    homePage: "Heim",
    aboutUs: "Über uns",
    contact: "Kontakt",
    comingSoon: "Demnächst !",
    petFoodCategoryName: "TIERFUTTER",
    petFoodDescription:
      "(DE) Lorem ipsum dolor sit amet constectır adipsilising elit. Vero name ?",
    petFoodFlipDescription:
      "(DE) Sed convallis varius risus facilisis egestas. Curabitur lacus augue, blandit eu arcu ut, facilisis accumsan nulla.",
    agriculturalCategoryName: "LANDWIRTSCHAFTLICHES PRODUKT",
    agriculturalDescription:
      "Wir werden schon bald mit unseren Agrarprodukten bei Ihnen, unseren geschätzten Kunden, sein.",
    address: "Adresse",
    changeAddress: "Adresse ändern",
    contactUs: "Kontaktiere uns",
    contactUsWithWP: "Kontaktieren Sie uns per WhatsApp",
    contactUsFormFullName: "VOLLSTÄNDIGER NAME",
    contactUsFormEmail: "EMAIL",
    contactUsFormPhone: "TELEFON",
    contactUsFormMessage: "NACHRICHT",
    contactUsFormSend: "Schicken",
    turkey: "Truthahn",
    europe: "Europa",
    footerBand: "© 2024 Copyright alle Rechte vorbehalten.",
    turkish: "Türkisch",
    english: "Englisch",
    german: "Deutsch",
    arabic: "Arabisch",
    requiredFormRuleMessage: "Darf nicht leer gelassen werden.",
    emailFormRuleMessage: "E-mail Adresse ist nicht korrekt.",
    minFormRuleMessage: "Sie müssen die Mindestzeichenanzahl überschreiten.",
    maxFormRuleMessage:
      "Sie dürfen die maximale Zeichenanzahl nicht überschreiten.",
    character: "Charakter",
  },
  ar: {
    homePage: "بيت",
    aboutUs: "معلومات عنا",
    contact: "اتصال",
    comingSoon: "قريباً",
    petFoodCategoryName: "طعام الحيوانات الاليفة",
    petFoodDescription:
      "(AR) Lorem ipsum dolor sit amet constectır adipsilising elit. Vero name ?",
    petFoodFlipDescription:
      "(AR) Sed convallis varius risus facilisis egestas. Curabitur lacus augue, blandit eu arcu ut, facilisis accumsan nulla.",
    agriculturalCategoryName: "المنتجات الزراعية",
    agriculturalDescription:
      "سنكون معكم عملائنا الكرام بمنتجاتنا الزراعية قريبا جدا",
    address: "عنوان",
    changeAddress: "تغيير العنوان",
    contactUs: "اتصل بنا",
    contactUsWithWP: "تواصل معنا عبر الواتساب",
    contactUsFormFullName: "الاسم الكامل",
    contactUsFormEmail: "بريد إلكتروني",
    contactUsFormPhone: "هاتف",
    contactUsFormMessage: "رسالة",
    contactUsFormSend: "يرسل",
    turkey: "ديك رومى",
    europe: "أوروبا",
    footerBand: "© 2024 حقوق الطبع والنشرمحفوظة",
    turkish: "اللغة التركية",
    english: "إنجليزي",
    german: "ألمانية",
    arabic: "عربي",
    requiredFormRuleMessage: "لا يمكن تركها فارغة",
    emailFormRuleMessage: "عنوان البريد الإلكتروني غير صالح",
    minFormRuleMessage: "يجب أن تتجاوز الحد الأدنى لعدد الأحرف",
    maxFormRuleMessage: "يجب ألا تتجاوز الحد الأقصى لعدد الأحرف",
    character: "شخصية",
  },
} as any;

export const getLanguageValue = (key: string, language: string) =>
  languageConstants[language][key];
